import { useGlobalContext } from '../../context/context';

import { Link } from 'react-router-dom';
import { useEffect } from 'react';

const TermsOfUse = () => {
  const { resetPage } = useGlobalContext();

  useEffect(() => {
    resetPage(`Terms of Service`);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <section className='terms'>
        <div className='block'>
          <h2>Terms of Service</h2>
          <p>
            This Terms of Service offers you an overview of the key terms that
            apply to your use of our website, trading and other services. While
            we hope this summary section is helpful, you should read the
            complete Terms of Service below since they provide important
            information about how our services work. Please note that we refer
            to our online service where you can execute trades and access our
            services as Bitstradestation.
            <br />
            Bitstradestation provides you with a platform that matches your
            trades with open orders from other users of our services at your
            direction. Users are not able to predetermine a trade with a
            particular user or with a particular account. Additionally, an order
            may be partially filled or may be filled by multiple matching
            orders.
          </p>
        </div>
        <div className='block'>
          <h2>Our Services</h2>
          <p>
            Bitstradestation provides you with a simple and convenient way to
            trade legal tender (such as U.S. dollars and Euros) for digital
            assets (such as bitcoins and ripples) and vice versa, and to trade
            one type of digital asset for another type of digital asset. You may
            also use our Services to purchase and sell digital assets directly
            from and to us. Additionally, the range of services available to you
            will depend in part upon the country or U.S. state of which you are
            a resident and from which you access Bitstradestation.
            <br />
            Bitstradestation provides you with a platform that matches your
            trades with open orders from other users of our services at your
            direction. Users are not able to predetermine a trade with a
            particular user or with a particular account. Additionally, an order
            may be partially filled or may be filled by multiple matching
            orders.
          </p>
        </div>
        <div className='block'>
          <h2>Eligibility and Acceptable Use</h2>
          <p>
            You must meet certain eligibility criteria to use Bitstradestation.
            For instance, you must be an adult and there are certain locations
            from which you may not be able to use some or all of Trade Tech
            Coin. Additionally, there are certain things you cannot do when
            using Bitstradestation, such as engage in illegal activities, lie,
            or do anything that would cause damage to our services or systems.
            Please see the acceptable use section for more details
          </p>
        </div>
        <div className='block'>
          <h2>Trading Risks</h2>
          <p>
            Engaging in any trade can be financially risky, and there can be
            higher financial risks if you engage in any margin transactions, use
            any other sophisticated trading options, or trade in digital assets
            that are subject to volatile market price movements. Please
            don&apos;t use Bitstradestation if you do not understand these risks
            and enter into trades only when you understand the trading option
            you are using, the characteristics of the digital asset you intend
            to trade, and the potential financial risk of loss trading them
            entails.
          </p>
        </div>
        <div className='block'>
          <h2>Other Important Legal Terms</h2>
          <p>
            There are important legal terms provided below in the complete Terms
            of Service, including your indemnification responsibilities, our
            limitation of liability and warranty disclaimers, and your agreement
            to arbitrate most disputes. Please take the time to read these terms
            carefully. You can always contact us through support if you have any
            questions at{' '}
            <Link to='/contact' className='link'>
              Support
            </Link>
          </p>
        </div>
        <div className='block'>
          <h2>Complete Terms of Service</h2>
          <p>
            These Terms of Service and any terms expressly incorporated herein
            (&quot;Terms&quot;) apply to your access to and use of the websites
            and mobile applications provided by Payward, Inc. and its wholly
            owned subsidiaries (collectively, &quot;Payward&quot;, &quot;Trade
            Tech Coin&quot;, &quot;we&quot;, or &quot;us&quot;), and the
            trading, direct sale, staking and other services provided by Payward
            as described in these Terms. (collectively, our
            &quot;Services&quot;).
          </p>
        </div>
        <div className='block'>
          <h2>Bitstradestation Account</h2>
          <p>
            Number of Bitstradestation Accounts. Payward may, in its sole
            discretion, limit the number of Bitstradestation Accounts that you
            may hold, maintain or acquire. Bitstradestation Account information
            and security. In order to engage in any trades via the Services, you
            must create a Bitstradestation Account and provide any requested
            information. When you create a Bitstradestation Account, you agree
            to: (a) create a strong password that you do not use for any other
            website or online service; (b) provide accurate and truthful
            information; (c) maintain and promptly update your Bitstradestation
            Account information; (d) maintain the security of your Trade Tech
            Coin Account by protecting your password and restricting access to
            your Bitstradestation Account; (e) promptly notify us if you
            discover or otherwise suspect any security breaches related to your
            Trade Tech Coin Account; and (f) take responsibility for all
            activities that occur under your Bitstradestation Account and accept
            all risks of any authorized or unauthorized access to your
            Bitstradestation Account, to the maximum extent permitted by law.
          </p>
        </div>
        <div className='block'>
          <h2>Privacy Notice</h2>
          <p>
            Please refer to our{' '}
            <Link to='/privacy-policy' className='link'>
              Privacy Notice{' '}
            </Link>{' '}
            for information about how we collect, use and share your
            information.
          </p>
        </div>
        <div className='block'>
          <h2>General Obligations</h2>
          <p>
            This Section 5 applies to: (i) all trades completed via the
            Services, (ii) your purchase and/or sale of Digital Assets directly
            from Payward via the Services, and (iii) any transaction in which
            you load Funds into your Bitstradestation Account from your External
            Account or transfer Funds from your Bitstradestation Account into an
            External Account. <br />
            <br /> We may, at any time and in our sole discretion, refuse any
            trade submitted via the Services, impose limits on the trade amount
            permitted via the Services or impose any other conditions or
            restrictions upon your use of the Services for funding your Trade
            Tech Coin Account or for trading without prior notice. For example,
            we may limit the number of open orders that you may establish via
            the Services or we may restrict trades from certain locations.{' '}
            <br />
            <br /> You must provide any information required when creating a
            Bitstradestation Account or when prompted by any screen displayed
            within the Services. You represent and warrant that any information
            you provide via the Services is accurate and complete. <br />
            <br /> You may only cancel an order initiated via the Services if
            such cancellation occurs before Payward executes the transaction.{' '}
            <br />
            <br /> If you have an insufficient amount of Funds in your Trade
            Tech Coin Account to complete an order via the Services, we may
            cancel the entire order or may fulfill a partial order using the
            amount of Funds currently available in your Bitstradestation
            Account, less any fees owed to Payward in connection with our
            execution of the trade (as described in Section 10). <br /> <br />{' '}
            It is your responsibility to determine what, if any, taxes apply to
            the trades you complete via the Services, and it is your
            responsibility to report and remit the correct tax to the
            appropriate tax authority. You agree that Payward is not responsible
            for determining whether taxes apply to your trades or for
            collecting, reporting, withholding or remitting any taxes arising
            from any trades. <br /> <br /> In order to complete an order or
            trade via the Services (as described in Section 7), you must first
            load Funds to your Bitstradestation Account using one of the
            approved External Accounts identified via the Services. You may be
            required to verify that you control the External Account that you
            use to load Funds to your Bitstradestation Account. As further
            described in Section 10, you may be charged fees by the External
            Account you use to fund your Bitstradestation Account. Payward is
            not responsible for any External Account fees or for the management
            and security of any External Account. You are solely responsible for
            your use of any External Account, and you agree to comply with all
            terms and conditions applicable to any External Account. The timing
            associated with a load transaction will depend in part upon the
            performance of third parties responsible for maintaining the
            applicable External Account, and Payward makes no guarantee
            regarding the amount of time it may take to load Funds into your
            Bitstradestation Account. <br /> <br /> Be advised that fiat funds
            held in your Bitstradestation account are exclusively for the
            purchase of Digital Assets or withdrawal to your approved External
            Account. Proceeds from the sale of Digital Assets will be credited
            to your fiat account, less any transactional or other fees.
            Furthermore, be advised that Bitstradestation does not pay interest
            on free fiat balances held in your account. <br />
            <br /> If you have opened a Bitstradestation Account designated as
            Digital Assets Only, you may only fund your account with digital
            assets. Bitstradestation will not accept fiat to fund a Digital
            Assets Only Account. If fiat is transmitted to fund such an account,
            it will be returned to the sender, less applicable transfer fees.{' '}
            <br />
            <br /> Provided that the balance of Funds in your Bitstradestation
            Account is greater than any minimum balance requirements needed to
            satisfy any of your open positions, and subject to the restrictions
            set forth in Section 7.8, you may transfer any amount of Funds, up
            to the total amount of Funds in your Bitstradestation Account in
            excess of such minimum balance requirements, from your Trade Tech
            Coin Account to an External Account, less any fees charged by
            Payward for such transactions (as described in the Fee Schedule at
            the time of your request to transfer Funds to an External Account).{' '}
            <br />
            <br /> When you request that we load Funds into your
            Bitstradestation Account from your External Account or request that
            we transfer Funds to your External Account from your
            Bitstradestation Account, you authorize Payward to execute such
            transaction via the Services. <br />
            <br /> In some cases, the External Account may reject your Funds or
            may otherwise be unavailable to receive your Funds. You agree that
            you will not hold Payward liable for any damages resulting from such
            rejected transactions. When you submit a new order via the Services,
            you authorize Payward to execute a transaction in accordance with
            such order on a spot basis and charge you any applicable fees (as
            described in Section 10). <br />
            <br /> You acknowledge and agree that: (a) Payward is not acting as
            your broker, intermediary, agent, or advisor or in any fiduciary
            capacity, and (b) no communication or information provided to you by
            Payward shall be considered or construed as advice. <br />
            <br /> Once the Services execute your trade, a confirmation will be
            electronically made available via the Services detailing the
            particulars of the trade. <br />
            <br /> You acknowledge and agree that the failure of the Services to
            provide such confirmation shall not prejudice or invalidate the
            terms of such trade. If you select a market trade, Payward will
            attempt, on a commercially reasonable basis, to execute the trade on
            or close to the prevailing market exchange rate, as defined via the
            Services. You acknowledge and agree that the exchange rate
            information made available via our Services may differ from
            prevailing exchange rates made available via other sources outside
            of the Services. <br />
            <br /> Particularly during periods of high volume, illiquidity, fast
            movement or volatility in the marketplace for any Digital Assets or
            Legal Tender, the actual market rate at which a market order or
            trade is executed may be different from the prevailing rate
            indicated via the Services at the time of your order or trade. You
            understand that we are not liable for any such price fluctuations.
            In the event of a market disruption or Force Majeure event (as
            defined in Section 24), Payward may do one or more of the following:
            (a) suspend access to the Services; or (b) prevent you from
            completing any actions via the Services, including closing any open
            positions. Following any such event, when trading resumes, you
            acknowledge that prevailing market rates may differ significantly
            from the rates available prior to such event. <br />
            <br /> Subject to the terms and conditions in these Terms, we will
            use commercially reasonable efforts to settle trades on a spot basis
            within two (2) days of the date upon which users have agreed to
            execute a trade via the Services.
          </p>
        </div>
      </section>
    </>
  );
};

export default TermsOfUse;
