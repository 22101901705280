// IMAGES FOR CRYPTO ASSETS
import Bitcoin from '../assets/bitcoin.svg';
import Ethereum from '../assets/ethereum-eth-logo.svg';
import Ripple from '../assets/xrp-xrp-logo.svg';
import BitcoinCash from '../assets/bitcoin-cash-bch-logo.svg';
import Litecoin from '../assets/litecoin-ltc-logo.svg';
import Eosio from '../assets/eos-eos-logo.svg';

// IMAGES FOR INVESTMENTS
import Cryptocurrency from '../assets/cryptocurrency.png';
import Etfs from '../assets/etfs.png';
import Options from '../assets/options.png';
import Bonds from '../assets/bond.png';
import StockMarket from '../assets/stock-market.png';
import IndexFunds from '../assets/index-funds.png';

// IMAGES FOR CONTACTS
import WhatsApp from '../assets/live-chat-support.svg';
import Phone from '../assets/phone.svg';
import Email from '../assets/email.svg';

// IMAGES FOR ABOUT HEADER
import aboutHeaderCoins from '../assets/about-header-coins.png';
import aboutHeaderTransactions from '../assets/about-header-transactions.png';
import aboutHeaderSupport from '../assets/about-header-support.png';

// IMAGES FOR CRYPTO PORTFOLIO
import kyc from '../assets/kyc.svg';
import user from '../assets/user.svg';
import spot from '../assets/spot.svg';

// IMAGES FOR TRUSTED TRADING PLATFORM
import secureAsset from '../assets/secure-asset.svg';
import accessControl from '../assets/access-control.svg';

// IMAGES FOR HOMEPAGE HELP SECTION
import chatSupport from '../assets/cs.svg';
import FAQ from '../assets/faq.svg';
import blog from '../assets/blog.svg';

export const assets = [
  {
    logo: `${Bitcoin}`,
    name: `BITCOIN (BTC)`,
    info: `Bitcoin is an open-source software that since 2009 has enabled the exchange of an entirely new form of money (BTC) over the internet.`,
  },
  {
    logo: `${Ethereum}`,
    name: `ETHEREUM (ETH)`,
    info: `One of the most ambitious cryptocurrencies to date, Ethereum was launched in 2014 to decentralize products and services in a broader array of use cases beyond money.`,
  },
  {
    logo: `${Ripple}`,
    name: `RIPPLE (XRP)`,
    info: `XRP is a cryptocurrency that aims to complement traditional payments, migrating transactions that occur today between databases controlled by financial firms to a more open infrastructure.`,
  },
  {
    logo: `${BitcoinCash}`,
    name: `BITCOIN CASH (BCH)`,
    info: `Launched in 2017, Bitcoin Cash (BCH) is a newer cryptocurrency that intends to offer an alternative to the world's oldest and most widely traded cryptocurrency, Bitcoin (BTC).`,
  },
  {
    logo: `${Litecoin}`,
    name: `LITECOIN (LTC)`,
    info: `Litecoin (LTC) is one of the very first projects to copy and modify Bitcoin's code and use it to launch a new cryptocurrency.`,
  },
  {
    logo: `${Eosio}`,
    name: `EOSIO (EOS)`,
    info: `The cryptocurrency that powers the EOSIO blockchain, buying EOS is essential for developers who must buy and stake EOS to launch decentralized applications (dapps).`,
  },
];

export const investments = [
  {
    logo: `${Cryptocurrency}`,
    name: `CryptoCurreny`,
    info: `Cryptocurrencies like Bitcoin are digital currencies that are not backed by real assets or tangible securities. They are traded between consenting parties without a broker and tracked in a digital ledger.`,
  },
  {
    logo: `${Etfs}`,
    name: `ETFS`,
    info: `An exchange-traded fund (ETF) is a type of security that tracks indexes, sectors, commodities, or other assets, but can be bought and sold on the exchange in the same way as regular stocks. ETFs can be configured to track everything from single commodity prices to large and diverse stock collections. ETFs can also be configured to pursue specific investment strategies.`,
  },
  {
    logo: `${Options}`,
    name: `Options`,
    info: `The term option refers to a financial instrument based on the value of the underlying security, such as a stock. Option contracts provide buyers with the opportunity to buy or sell the underlying asset, depending on the type of contract they hold. Unlike futures, if the holder chooses not to buy or sell the asset, he or she does not need to buy or sell the asset. Each contract has a specific expiration date on which the holder must exercise the option.`,
  },
  {
    logo: `${Bonds}`,
    name: `Bonds`,
    info: `A bond is a bond that represents a loan from an investor to a borrower (usually a company or government). Bonds can be considered I.O.U. Provides loan and payment details between the lender and the borrower. Public debt is used by businesses, local governments, states, and state governments to fund projects and operations. The bond holder is the issuer's debtor or creditor..`,
  },
  {
    logo: `${StockMarket}`,
    name: `Stocks`,
    info: `Investing in stock means buying a small portion of the ownership of a listed company. These small stocks are known as company stocks, and when investing in them, you want the company to grow and function well over time. If so, your stock may increase in value, and other investors may be willing to buy them from you for more than you paid. This means that if you choose to sell them, you can make a profit.`,
  },
  {
    logo: `${IndexFunds}`,
    name: `Index Funds`,
    info: `An index fund is a type of investment fund or exchange traded fund (ETF) with a portfolio configured to match or duplicate the components of a financial market index, such as the Standard & Poor's 500 Index (S & P 500). Index mutual funds are designed to offer a wide range of market exposures, low operating costs and low portfolio turnover. These funds follow the benchmark index regardless of market conditions.`,
  },
];

export const contacts = [
  {
    logo: `${WhatsApp}`,
    method: `WhatsApp`,
    link: `whatsapp://send?text=Hello, my name is &phone=+17542330784`,
  },
  {
    logo: `${Phone}`,
    method: `Phone Support`,
    link: `/contact`,
  },
  {
    logo: `${Email}`,
    method: `Submit a ticket request`,
    link: `mailto: support@bitstradestation.com`,
  },
];

export const planPrices = [
  {
    planName: `Starter`,
    planPrice: `$500.00 - $999.00`,
    planFeatures: [
      `eToro (Trading Bot)`,
      `20 positions`,
      `portfolio management`,
      `All Available Exchanges`,
    ],
  },
  {
    planName: `Premium`,
    planPrice: `$1000.00 - $2499.00`,
    planFeatures: [
      `CryptoHopper (Trading Bot)`,
      `80 positions`,
      `portfolio management`,
      `All Available Exchanges`,
    ],
  },
  {
    planName: `Master`,
    planPrice: `$2500.00 - $5499.00`,
    planFeatures: [
      `3Commas (Trading Bot)`,
      `200 positions`,
      `portfolio management`,
      `All Available Exchanges`,
    ],
  },
  {
    planName: `Pro`,
    planPrice: `$5500.00`,
    planFeatures: [
      `Pionex (Trading Bot)`,
      `500 positions`,
      `Portfolio Management`,
      `All coins for signals`,
      `Market Arbitrage`,
      `Algorithm Intelligence`,
    ],
  },
];

// HOME PAGE HEADER STATS
export const headerInfo = [
  {
    num: `$20 thousand`,
    brief: `24h trading volume on Company exchange`,
  },
  {
    num: `50+`,
    brief: `Cryptocurrencies listed`,
  },
  {
    num: `1 thousand`,
    brief: `Registered users`,
  },
  {
    num: `<0.10%`,
    brief: `Lowest transaction fees`,
  },
];

export const aboutInfo = [
  {
    stat: `20,000`,
    text: `Average daily volume`,
    icon: aboutHeaderCoins,
  },
  {
    stat: `10,000+`,
    text: `Transactions per second`,
    icon: aboutHeaderTransactions,
  },
  {
    stat: `24/7`,
    text: `Customer Support in 40 languages`,
    icon: aboutHeaderSupport,
  },
];

export const portfolioSteps = [
  {
    step: `Verify your identity`,
    info: `Complete the identity verification process to secure your account and transactions.`,
    img: kyc,
  },
  {
    step: `Fund your account`,
    info: `Add funds to your crypto account to start trading crypto. You can add funds with a variety of payment methods.`,
    img: user,
  },
  {
    step: `
Start trading`,
    info: `You're good to go! Buy/sell crypto, set up recurring buys for your investments, and discover what Binance has to offer.`,
    img: spot,
  },
];

export const trustedFeatures = [
  {
    step: `Secure Asset Fund for Users (SAFU)`,
    info: `Binance stores 10% of all trading fees in a secure asset fund to protect a share of user funds.`,
    img: secureAsset,
  },
  {
    step: `Personalised Access Control`,
    info: `Personalized access control allows you to restrict devices and addresses that can access your account, for greater ease of mind.`,
    img: accessControl,
  },
  {
    step: `Advanced Data Encryption`,
    info: `Your transaction data is secured via end-to-end encryption, ensuring that only you have access to your personal information.`,
    img: spot,
  },
];

export const HelpCenter = [
  {
    logo: chatSupport,
    name: `24/7 Chat Support`,
    info: `Get 24/7 chat support with our friendly customer service agents at your service.`,
    prompt: `Chat now`,
    link: `#`,
  },
  {
    logo: FAQ,
    name: `Services`,
    info: `Learn more about the services we render and how you might benefit from any of them`,
    prompt: `Learn More`,
    link: `services`,
  },
  {
    logo: blog,
    name: `Prices`,
    info: `Take a look at our prices page, to determine what the best investment plan is for you`,
    prompt: `Check it out`,
    link: `prices`,
  },
];

// CUSTOMERS ACTIVITIES

export const customersActivites = [
  {
    message: `Someone in the US just made a withdrawal of $1000`,
  },
  {
    message: `Someone in South Africa just made a deposit of $5000`,
  },
  {
    message: `Someone in Mexico just made a withdrawal request of $30000`,
  },
  {
    message: `Someone's withdrawal request of $1000 just got approved`,
  },
  {
    message: `Someone's deposit of $1000 in the UK has been confirmed`,
  },
  {
    message: `Someone in Deli just made a withdrawal request of $4500`,
  },
  {
    message: `Someone in Taiwan just made a withdrawal of $9000`,
  },
];
